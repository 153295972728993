import { BASE_PATH } from '@benefits/common-utils';
import { logger } from '@benefits/tracking';

export const registerServiceWorker = async (): Promise<unknown> => {
  if ('serviceWorker' in navigator) {
    logger.info('Service Worker is supported');

    try {
      const registration = await navigator.serviceWorker.register(`${BASE_PATH}/sw.js`);

      logger.info('ServiceWorker registration sucessful with scope:', registration.scope);

      return await waitUntilServiceWorkerInstalled(registration);
    } catch (err) {
      logger.error('ServiceWorker registration failed: ', err as string);
    }
  } else {
    logger.error('Service Worker is NOT supported');
  }
};

export const waitUntilServiceWorkerInstalled = (registration: ServiceWorkerRegistration): Promise<unknown> => {
  return new Promise((resolve) => {
    if (registration.installing?.state !== 'installing') {
      resolve(registration);
    }

    logger.time('Service Worker Installation');

    if (registration.installing) {
      registration.installing.onstatechange = () => {
        if (registration.active?.state === 'activated') {
          logger.timeEnd('Service Worker Installation');

          resolve(registration);
        }
      };
    }
  });
};
