import { getPermissions } from '@benefits/auth-services';
import { AccessControlProvider, UserContext } from '@benefits/common-services';
import { isTokenExpired } from '@benefits/common-utils';
import { Loading } from '@benefits/components';
import { useAcessControlEnabled } from '@benefits/features';
import { QUERY_CLIENT } from '@benefits/http-client';
import { Auth as AuthRouter } from '@benefits/router-services';
import { hotjar, updateAboyeurMetadata, useClarityTracking } from '@benefits/tracking';
import Router, { useRouter } from 'next/router';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Userpilot } from 'userpilot';

import { SAFE_ROUTES } from './Auth.routes';

type AuthProps = PropsWithChildren<{
  currentPath: string;
}>;

const Auth = ({ children, currentPath }: AuthProps) => {
  const context = useContext(UserContext);
  const { pathname } = useRouter();
  const [ready, setReady] = useState(false);
  const IS_SAFE_ROUTE = SAFE_ROUTES.some((r) => currentPath.includes(r));
  const { identifyClarityUser, setCustomTag } = useClarityTracking();
  const { isAccessControlEnabled } = useAcessControlEnabled();

  const isAccessControlDisabled = !isAccessControlEnabled;

  useEffect(() => {
    const listener = () => {
      const user = context?.state?.user;
      if (!IS_SAFE_ROUTE && (!user?.accessToken || isTokenExpired(user?.accessToken) || !user.group)) {
        context?.dispatch?.clear();
        Router.push(AuthRouter.LOGIN);
      } else {
        setReady(true);
      }
    };
    Userpilot.reload();
    listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const user = context?.state?.user;

    if (user?.userId) {
      updateAboyeurMetadata({
        amplitude: { userId: user?.userId },
      });
      identifyClarityUser(user?.userId as string);
      setCustomTag('groupId', user?.group?.id || '');

      if (hotjar.initialized()) {
        hotjar.identify(user.userId, { groupId: user?.group?.id });
      }

      QUERY_CLIENT.removeQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.state?.user?.group?.id]);

  if (!ready) {
    return <Loading />;
  }

  return (
    <AccessControlProvider disabled={IS_SAFE_ROUTE || isAccessControlDisabled} permissionsLoader={getPermissions}>
      {children}
    </AccessControlProvider>
  );
};

export default Auth;
