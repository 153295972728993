import { variables } from '@benefits/environment';
import Script from 'next/script';

export const MicrosoftClarityScript: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Script id="microsoft-clari-script" strategy="afterInteractive">
    {`
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${variables.NEXT_PUPLIC_CLARITY_ID}");
      `}
  </Script>
);
