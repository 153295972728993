import jwt_decode from 'jwt-decode';

type AuthRole = string;

type UserAuthProduct = string;

type AuthRoleCollection = AuthRole[];

type UserAuthProductCollection = UserAuthProduct[];

export type JwtPayload = {
  USER_ID: string;
  USER_NAME: string;
  GROUP_ID: string;
  ROLES: AuthRoleCollection;
  PRODUCTS: UserAuthProductCollection;
  iss: string;
  exp: number;
  iat: number;
  sub: string;
};

export const getDecodedJWT = (encodedToken: string): JwtPayload => {
  return jwt_decode(encodedToken);
};

export const isTokenExpired = (encodedToken: string) => {
  try {
    const jwt = getDecodedJWT(encodedToken);
    if (jwt.exp < Date.now() / 1000) {
      return true;
    }
  } catch (e) {
    return true;
  }

  return false;
};
