import { HttpMethods, HttpStatusCodes, UnexpectedError, makeRequest } from '@benefits/http-client';

import { BFF_URL, endpoints } from './endpoints';

type Permissions = {
  [namespace: string]: string[];
};

export const getPermissions = async () => {
  const response = await makeRequest<Permissions>({
    baseURL: `${BFF_URL}/iam`,
    method: HttpMethods.GET,
    url: endpoints.v1.permissions.get,
  });

  switch (response?.status) {
    case HttpStatusCodes.OK:
      return response.body;
    case HttpStatusCodes.BAD_REQUEST:
    default:
      throw new UnexpectedError(response?.error);
  }
};
