import { useRouter } from 'next/router';
import { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react';

import type {
  ColumnMap,
  DraftContextType,
  DraftId,
  SetExampleDataCollectionDTO,
  SetUploadedFileDTO,
  SetUploadedFileIdDTO,
  UploadedFile,
  UploadedFileLayout,
} from './DraftProvider.types';

export const DraftContext = createContext<DraftContextType>({});

const extractShortId = (draftID?: string) => {
  try {
    return draftID?.split('-')[3].toUpperCase() || undefined;
  } catch {
    return '';
  }
};

export const DraftProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter();
  const draftFullId = useMemo(() => (router?.query?.draftId as DraftId) ?? undefined, [router?.query?.draftId]);
  const shortId = useMemo(() => extractShortId(draftFullId), [draftFullId]);

  const [draftId, setDraftId] = useState<DraftId>(draftFullId);
  const [shortDraftId, setShortDraftId] = useState<DraftId>();
  const [file, setFile] = useState<UploadedFile>();
  const [fileId, setFileId] = useState<SetUploadedFileIdDTO>();
  const [headers, setHeaders] = useState<UploadedFileLayout>();
  const [columnMap, setColumnMap] = useState<ColumnMap>();
  const [exampleData, setExample] = useState<SetExampleDataCollectionDTO>();

  const setUploadedFile = useCallback(
    (data: SetUploadedFileDTO) => {
      setFile(data.file);
      setHeaders(data.headers);
    },
    [setFile, setHeaders]
  );

  const setUploadedFileId = useCallback(
    (fileId: SetUploadedFileIdDTO) => {
      setFileId(fileId);
    },
    [setFileId]
  );

  const setColumnMapping = useCallback(
    (map: ColumnMap) => {
      setColumnMap(map);
    },
    [setColumnMap]
  );

  const setExampleData = useCallback(
    (examples: SetExampleDataCollectionDTO) => {
      setExample(examples);
    },
    [setExample]
  );

  useEffect(() => {
    setDraftId((prevDraftId) => {
      if (prevDraftId !== draftFullId) {
        setDraftId(draftFullId);
      }

      return prevDraftId;
    });
  }, [draftFullId]);

  useEffect(() => {
    setShortDraftId((prevShortId) => {
      if (prevShortId !== shortId) {
        setShortDraftId(shortId);
      }
      return prevShortId;
    });
  }, [shortId]);

  return (
    <DraftContext.Provider
      value={{
        columnMap,
        draftId,
        shortDraftId,
        file,
        fileId,
        headers,
        exampleData,
        setDraftId,
        setShortDraftId,
        setColumnMapping,
        setUploadedFile,
        setUploadedFileId,
        setExampleData,
      }}
    >
      {children}
    </DraftContext.Provider>
  );
};
