import { UseRouterArgsType } from '../hooks/types';
import { useRouter } from '../hooks/useRouter/useRouter';

const Interceptor: React.FC<UseRouterArgsType> = ({ onPush, onReplace, basePath }) => {
  useRouter({ onPush, onReplace, basePath });

  return null;
};

export { Interceptor as RedirectInterceptor };
