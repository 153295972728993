import TagManager from 'react-gtm-module';

type DataLayerType = Record<string, any>;

declare global {
  interface Window {
    dataLayer: DataLayerType[];
  }
}

export const dataLayer = (data: DataLayerType) => {
  const isInitialized = typeof window !== undefined && window?.dataLayer !== undefined;

  if (isInitialized) {
    TagManager.dataLayer({
      dataLayer: data,
    });
  }
};
