const buildPath = (...args: string[]): string => {
  const entries = args.filter((arg) => arg);

  const startingSlash = entries[0].indexOf('/') === 0 ? '/' : '';

  const path = entries.map(
    (entry) =>
      entry &&
      entry
        .trim()
        .replace(/^\/+|\/+$/g, '')
        .replace(/([^:])\/{2,}/g, '$1/')
  );

  return (startingSlash + path.join('/')).replace(/([^:])\/{2,}/g, '$1/').replace(/^\/+/, '/');
};

export { buildPath };
