import { createGlobalStyle } from 'styled-components';

const UserPilotNpsStyle = createGlobalStyle`
  iframe#userpilot-nps {
    width: 100vw !important;
    height: 100vh !important;
    background: #3e3e3e66;
  }
`;

export { UserPilotNpsStyle };
