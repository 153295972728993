import { ClarityResponse } from './useClarityTracking.types';

declare global {
  interface Window {
    clarity: any;
  }
}

export const useClarityTracking = () => {
  const clarity = typeof window !== 'undefined' && window?.clarity;
  const isClarityLoaded = clarity !== undefined && typeof clarity === 'function';

  const identifyClarityUser = async (
    email: string,
    sessionId?: string,
    pageId?: string
  ): Promise<ClarityResponse | undefined> => {
    if (!isClarityLoaded) return;

    try {
      const response = await clarity('identify', email, sessionId, pageId);
      return response;
    } catch (error) {
      console.error('Error identifying user:', error);
    }
  };

  const setCustomTag = (tag: string, value: string | string[]): void => {
    if (isClarityLoaded) clarity('set', tag, value);
  };

  const addCustomEvent = (value: string): void => {
    if (isClarityLoaded) clarity('event', value);
  };

  const prioritizeSession = (reason: string) => {
    if (isClarityLoaded) clarity('upgrade', reason);
  };

  const consentTrackingSession = () => {
    if (isClarityLoaded) clarity('consent');
  };

  return {
    addCustomEvent,
    identifyClarityUser,
    setCustomTag,
    prioritizeSession,
    consentTrackingSession,
  };
};
