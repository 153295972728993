import { useSnackbar } from '@benefits/common-services';
import { Snackbar } from '@benefits/components';
import { useMessage } from '@benefits/message';

const SnackBarContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { snacks, position, saveSnacks } = useSnackbar();
  useMessage('snackbar', {
    onReceive: (snackProps: any) => {
      saveSnacks(snackProps);
    },
  });

  return <Snackbar position={position} snacks={snacks} />;
};

export { SnackBarContainer };
