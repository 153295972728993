import { UserStore } from '@benefits/common-services';
import { AppLayout, SpacingProvider } from '@benefits/components';
import { FeatureStore, ToggleStore } from '@benefits/features';
import { QueryClientProvider } from '@benefits/http-client';
import { PomodoroProvider, benefitsWebTheme, pomodoroTheme } from '@benefits/pomodoro-components';
import { ProductStore } from '@benefits/product-services';
import { RedirectInterceptor } from '@benefits/router-services';
import { fitsTheme } from '@benefits/theme';
import { EventsListener } from '@benefits/tracking';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Scripts } from '../../components';
import { UserPilotNpsStyle } from '../../components/UserPilotNps/UserPilotNps.styles';
import { DraftProvider } from '../../utils/mfe/Employee/providers/DraftProvider';
import RemoteConfigProvider from '..//RemoteConfigProvider/RemoteConfigProvider';
import { SnackBarContainer } from '..//SnackBarContainer/SnackBarContainer';
import Auth from '../Auth/Auth';
import { ErrorBoundaryFallback } from '../ErrorBoundaryFallback/ErrorBoundaryFallback';
import type { AllProvidersProps } from './AllProviders.types';

const PageProgressBar = dynamic(() => import('../../components/PageProgressBar/PageProgressBar') as any, {
  ssr: false,
});
const Zendesk = dynamic(() => import('../../components/Zendesk/Zendesk') as any, { ssr: false });

export const AllProviders: React.FC<PropsWithChildren<AllProvidersProps>> = ({ children, pathname }) => {
  return (
    <QueryClientProvider>
      <RemoteConfigProvider>
        <UserStore>
          {/* Feature Toggles */}
          <ToggleStore>
            {/* Feature Flag */}
            <FeatureStore>
              <ProductStore>
                <PomodoroProvider
                  __UNSTABLE__theme={{ ...benefitsWebTheme }}
                  theme={{ ...pomodoroTheme, ...fitsTheme.light }}
                >
                  <EventsListener>
                    <RedirectInterceptor onPush={Router.push} onReplace={Router.replace} />
                    <PageProgressBar />
                    <UserPilotNpsStyle />
                    <DraftProvider>
                      <Auth currentPath={pathname}>
                        <Zendesk />
                        <SnackBarContainer />
                        <SpacingProvider>
                          <AppLayout>
                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>{children}</ErrorBoundary>
                          </AppLayout>
                        </SpacingProvider>
                      </Auth>
                    </DraftProvider>
                  </EventsListener>
                </PomodoroProvider>
              </ProductStore>
            </FeatureStore>
          </ToggleStore>
        </UserStore>
      </RemoteConfigProvider>
      <Scripts />
    </QueryClientProvider>
  );
};
