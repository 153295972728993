import { variables } from '@benefits/environment';
import TagManager from 'react-gtm-module';

const gtmInitialize = () => {
  if (!variables.GTM_ID) {
    return;
  }

  TagManager.initialize({ gtmId: variables.GTM_ID });
};

export { gtmInitialize, TagManager };
