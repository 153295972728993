import { Button, Heading1, IfdlIcon, Text } from '@benefits/pomodoro-components';
import { useEffect } from 'react';

import { useErrorEventDispatcher } from '../../services/useErrorDispatcher';
import { Image } from '../Image';
import { ButtonsWrapper, Container } from './ErrorBoundaryFallback.styles';

type ErrorBoundaryFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorBoundaryFallback: React.FC<React.PropsWithChildren<ErrorBoundaryFallbackProps>> = ({
  resetErrorBoundary,
}) => {
  const dispatcher = useErrorEventDispatcher();

  useEffect(() => {
    dispatcher.sendEvent('CRASH');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickTryAgain = () => {
    resetErrorBoundary();
    dispatcher.sendEvent('CRASH_CLICK_TRY_AGAIN');
  };

  return (
    <Container>
      <Image
        alt="Ilustração de um pé tropeçando no cabo de um roteador de wifi e perdendo a conexão"
        height={200}
        src="/svgs/no_connection_G.svg"
        width={200}
      />

      <div>
        <Heading1 m={0} variant="large">
          Oh não, nossos serviços estão fora do ar
        </Heading1>
        <Text>
          Houve um erro ao abrir a página. <br />
          Por favor, tente novamente usando o botão abaixo.
        </Text>
      </div>

      <ButtonsWrapper>
        <Button data-testid="errorboundary_tryagain" onClick={handleClickTryAgain}>
          <IfdlIcon color="white" mr={8} name="update" size="s" /> Tentar novamente
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};
