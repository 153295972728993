import { useContext } from 'react';

import * as featureToggles from '../../toggle/featureToggles';
import { ToggleContext } from '../../toggle/ToggleStore';

export const useAcessControlEnabled = () => {
  const featureTogglesContent = useContext(ToggleContext);

  const isAccessControlEnabled = featureTogglesContent?.isEnable(featureToggles.BENF_COMPANIES_PLATFORM_ACL_ENABLED);

  return { isAccessControlEnabled };
};
