import NextImage, { ImageLoaderProps, ImageProps } from 'next/image';

import { loaderImage } from '../../utils/loaderImage/loaderImage';

export const Image = (props: ImageProps) => {
  const src: ImageLoaderProps['src'] = props?.src as string;
  const width: ImageLoaderProps['width'] = props?.width as number;

  const srcImage = loaderImage({ src, width });

  return <NextImage {...props} src={srcImage} />;
};
