/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { buildPath } from '@benefits/common-utils';
import { useMessage } from '@benefits/message';

import { event } from '../config';
import { PushArgs, UseRouterArgsType } from '../types';

const useRouter = ({ onPush, onReplace, basePath = '' }: UseRouterArgsType) => {
  useMessage(event, {
    onReceive: (options) => {
      redirect(options as PushArgs);
    },
  });

  const redirect = ({ url, alias, query, method = 'PUSH' }: PushArgs) => {
    const options = {
      scroll: true,
    };

    const asPath = alias || buildPath(basePath, url);

    (method === 'PUSH' ? onPush : onReplace)(
      {
        pathname: url,
        query,
      },
      asPath,
      options
    );
  };

  return null;
};

export { useRouter };
