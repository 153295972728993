import { Auth, Common, License } from '@benefits/router-services';

export const SAFE_ROUTES = [
  Common.FORBIDDEN,
  Auth.LOGIN,
  Auth.RESET_PASSWORD,
  Auth.LINK_EXPIRED,
  Auth.NEW_PASSWORD,
  Auth.REGISTRATION_2FA,
  Auth.REGISTRATION_PHONE_2FA,
  Auth.REGISTRATION_DOWNLOAD_APP_2FA,
  Auth.REGISTRATION_CONFIGURE_APP_2FA,
  Auth.REGISTRATION_VERIFICATION_CODE_2FA,
  Auth.REGISTRATION_SUCCESS_2FA,
  Auth.VALIDATION_VERIFICATION_CODE_2FA,
  Auth.AUTHENTICATION_FACTORS_2FA,
  License.LIST,
  License.DETAILS,
];
